import { Component } from '@angular/core';
// import { DomSanitizePipe } from '../../../data/pipes/domSanitize/dom-sanitize.pipe';
import { INVALID_ROLES } from 'src/app/common/constant/constant-variable/ConstantVariable';

@Component({
  selector: 'app-invalid-roles',
  standalone: true,
  imports: [],
  templateUrl: './invalid-roles.component.html',
  styleUrl: './invalid-roles.component.scss',
})
export class InvalidRolesComponent {
  INVALID_ROLES = INVALID_ROLES;
  constructor() {}
  tryAgain() {
    history.go(-2);
  }
}
