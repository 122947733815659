import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { ROUTING } from '../constant/routing/Routes';
import { AuthData } from '../interface/auth/user.model';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authData: AuthData;
  constructor(
    private _router: Router,
    private authService: AuthService
  ) {
    this.authService.authDataCast.subscribe((data: any) => (this.authData = data));
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authData.accessToken;
    const arHeaders: any = {};
    const url = request.url;
    const pattern = /api\.mailchimp\.com|login|code-verification/;
    if (pattern.test(url)) return next.handle(request);
    if (token) {
      arHeaders.Authorization = `Bearer ${token}`;
      request = request.clone({
        headers: new HttpHeaders(arHeaders),
      });
    } else {
      return next.handle(request).pipe(
        catchError(err => {
          return this.handleAuthError(err);
        })
      );
    }
    return next.handle(request).pipe(
      catchError(err => {
        return this.handleAuthError(err);
      })
    );
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status !== 422 && err.status !== 401 && err.status !== 403) {
      /* empty */
    }

    if (err.status === 502 || err.status === 504 || err.status === 500) {
      // this._loaderService.showUnderMaintenance()
    }
    if (err.status === 401) {
      localStorage.clear();
      this._router.navigate(['/auth/login']);
    }

    if (err.status === 403) {
      this._router.navigate([ROUTING.INVALID_ROLE]);
    }
    return throwError(() => err);
  }
}
