import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvalidRolesComponent } from './components/invalid-roles/invalid-roles.component';
import { InvalidRouteComponent } from './components/invalid-route/invalid-route.component';
import { ModuleType } from './common/enum/module-type/ModuleType.enum';
import { InvalidPackageComponent } from './components/invalid-package/invalid-package.component';
import { ACCESS_KEYS } from './common/constant/routing/Routes';

const routes: Routes = [
  {
    path: ModuleType.ManageAuth,
    data: {
      accessKey: ACCESS_KEYS.ACCESS_ALLOWED,
    },
    loadChildren: () => import('./modules/auth/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: '',
    // canActivateChild: [AdminGuard],
    loadChildren: () => import('./modules/features/features.module').then(m => m.FeaturesModule),
  },
  {
    path: 'invalid-role',
    component: InvalidRolesComponent,
  },
  {
    path: 'unauthorized',
    component: InvalidPackageComponent,
  },
  {
    path: 'error-page',
    loadChildren: () => import('./modules/features/pages/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
  },
  {
    path: '**',
    component: InvalidRouteComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
